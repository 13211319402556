import styled from 'styled-components'

const UnifiBanner = styled.section`
.warning-icon {
     color: var(--Content-content-warning, #FFAB00);
     font-size: 16px;
     font-style: normal;
     font-weight: 400;
     line-height: normal;
     padding-right: 8px;
}

.got-it-button{
    display: flex;
    margin-right: 0;
    padding: 2px 8px;
    padding-right: 0;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 4px;
    background: var(--Background-background-transparent, rgba(0, 0, 0, 0.00));

    color: var(--Content-content-button-transparent, rgba(0, 0, 0, 0.50));
    text-align: center;

    /* Button/btn-sm */
    font-family: Rubik;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px; /* 133.333% */
    letter-spacing: 0.24px;
}


  .main-container {
    display: flex;
    padding: 16px;
    margin-bottom: 24px;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    border-radius: 4px;
    border: 1px solid var(--Border-border-warning, #FFAB00);
    background: rgba(255, 229, 127, 0.20);

    .text-container {
        display: inline;
        flex-direction: column;
        align-items: flex-start;
        gap: 4px;

        color: var(--Content-content-main, #000);

    /* Body/p */
    font-family: Rubik;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */

    color: var(--Content-content-main, #000);
  }
`

export default { UnifiBanner }
