import React, { useState, useEffect } from 'react'
import { fetchData  } from '../../apis'
import apiConfig from '../../apis/apiConfig'
import { useHistory } from 'react-router-dom'
import { useDropdown } from '../../../hooks/useDropdown'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faTrashAlt, faWarning } from '@fortawesome/pro-regular-svg-icons'
import { useLocation } from 'react-router-dom'
import './styles.css'
import EditAddressModal from './EditAddressModal'
import AddAddressModal from './AddAddressModal'
import { getOrder, updateOrder } from './api'
import postAnalytics from '../../../utils/postAnalytics'
import { getRawCookie } from "../../../utils/cookies.js"
import { Dropdown } from '../../components/Dropdown'
import OrderEditItem from './OrderEditItem'

function OrderEdit({ userData, ...props }) {
  const history = useHistory()
  let query = useQuery()

  const orderId = query.get('orderId')
  const token = query.get('Token')

  const redirectUrl = decodeURIComponent(query.get('redirectUrl'))
  const requestId = decodeURIComponent(query.get('requestId'))

  const [orderItems, setOrderItems] = useState([])
  const [isSelectAddressModalOpen, setIsSelectAddressModalOpen] = useState(false)
  const [isAddAddressModalOpen, setIsAddAddressModalOpen] = useState(false)
  const [selectedAddress, setSelectedAddress] = useState(null)
  const [hasOneItemLeft, setHasOneItemLeft] = useState(false)
  const [showWarningMessage, setShowWarningMessage] = useState(false)
  const [isAddressAdded, setIsAddressAdded] = useState(false)
  const [contractCodes, setContractCodes] = useState([])
  const dropdownState = useDropdown()

  const isBrowser = typeof window !== 'undefined' && window.env
  const apiRoot = isBrowser ? window.env.apiRoot : process.env.RAZZLE_RUNTIME_API_ROOT;
  const apiKey = isBrowser
  ? window.env.apiKey
  : process.env.RAZZLE_RUNTIME_API_KEY;
  const blobUrl = isBrowser ? window.env.blobUrl : process.env.RAZZLE_RUNTIME_BLOB_URL

  async function saveAndUpdateOder() {
    const userToken = userData.Network[userData.activeNetworkIndex].UserToken
    const response = await updateOrder(userToken, orderId, token, orderItems, selectedAddress)

    if (response) {
      history.push(`${redirectUrl}?requestId=${requestId}`)
    }
  }

  function handleEditOrder(name, value, orderIndex, itemIndex) {
    const inputValue = parseInt(value, 10)

    if (isNaN(inputValue)) {
      setOrderItems((prevOrderItems) => {
        const tempOrderItems = [...prevOrderItems]
        tempOrderItems[orderIndex] = {
          ...tempOrderItems[orderIndex],
          Items: tempOrderItems[orderIndex].Items.map((item, index) => {
            if (index === itemIndex) {
              return {
                ...item,
                [name]: 1,
              }
            }
            return item
          }),
          TotalAmount: tempOrderItems[orderIndex].Items.reduce((sum, item) => sum + item.ListPrice * item.Quantity, 0),
        }

        return tempOrderItems
      })
    }

    setOrderItems((prevOrderItems) => {
      const tempOrderItems = [...prevOrderItems]
      tempOrderItems[orderIndex] = {
        ...tempOrderItems[orderIndex],
        Items: tempOrderItems[orderIndex].Items.map((item, index) => {
          if (index === itemIndex) {
            return {
              ...item,
              [name]: inputValue,
            }
          }
          return item
        }),
        TotalAmount: tempOrderItems[orderIndex].Items.reduce((sum, item) => sum + item.ListPrice * item.Quantity, 0),
      }

      return tempOrderItems
    })
  }

  const handleInputBlur = (name, value, orderIndex, itemIndex) => {
    const parsedValue = parseInt(value, 10)
    const updatedValue = isNaN(parsedValue) || parsedValue === 0 ? 1 : parsedValue

    setOrderItems((prevOrderItems) => {
      const tempOrderItems = [...prevOrderItems]
      tempOrderItems[orderIndex] = {
        ...tempOrderItems[orderIndex],
        Items: tempOrderItems[orderIndex].Items.map((item, index) => {
          if (index === itemIndex) {
            return {
              ...item,
              [name]: updatedValue,
            }
          }
          return item
        }),
        TotalAmount: tempOrderItems[orderIndex].Items.reduce((sum, item) => sum + item.ListPrice * item.Quantity, 0),
      }

      return tempOrderItems
    })
  }

  function handleRemoveItem(orderIndex, itemIndex) {
    setOrderItems((prevOrderItems) => {
      const tempOrderItems = [...prevOrderItems]
      tempOrderItems[orderIndex] = {
        ...tempOrderItems[orderIndex],
        Items: tempOrderItems[orderIndex].Items.filter((item, index) => index !== itemIndex),
      }
      if (tempOrderItems[orderIndex].Items.length === 0) {
        tempOrderItems.splice(orderIndex, 1)
      }
      return tempOrderItems
    })
  }

  function calculateListPriceSum(items) {
    let sum = 0
    for (const item of items) {
      for (const subItem of item.Items) {
        sum += subItem.ListPrice * subItem.Quantity
      }
    }

    if (isNaN(sum)) {
      return '-'
    }
    return new Intl.NumberFormat('en-US', {
      maximumFractionDigits: 2,
      currency: 'USD',
      style: 'currency',
    }).format(sum)
  }

  function generateSubtotal(items, index) {
    let subtotal = 0
    items.forEach((item) => {
      subtotal += item.Quantity * item.ListPrice
    })

    if (isNaN(subtotal)) {
      return '-'
    }

    return new Intl.NumberFormat('en-US', {
      maximumFractionDigits: 2,
      currency: 'USD',
      style: 'currency',
    }).format(subtotal)
  }

  const onSelect = async (option, index, secondIndex ) => {
    const selectedContractCode = contractCodes.find((cc) => cc.Id === option.id)

      setOrderItems((prevOrderItems) => {
        const tempOrderItems = [...prevOrderItems]

        tempOrderItems[index].Items[secondIndex] = {
          ...tempOrderItems[index].Items[secondIndex],
          ContractCodeId: selectedContractCode.Id,
          ContractCodeName: selectedContractCode.Name,
          ContractCodeDescription: selectedContractCode.Description
        }

      setOrderItems(tempOrderItems)

      return tempOrderItems
    })
  }

  const loadContractCodes = async () => {
    const companyId = await getRawCookie('companyId')    
    const options = {
        ...apiConfig.getCompanyContractCodes,
        url: `${apiConfig.getCompanyContractCodes.url}${companyId}/ContractCodes`,
      }
    const response = await fetchData(options)  

    if (response) {
      setContractCodes(response)
    }
  }


  function useQuery() {
    const { search } = useLocation()

    return React.useMemo(() => new URLSearchParams(search), [search])
  }

  useEffect(() => {
    if (orderItems.length === 1) {
      if (orderItems[0].Items.length === 1) {
        setHasOneItemLeft(true)
      }
    }
  }, [orderItems])

  useEffect(() => {
    const fetchOrders = async () => {
      const orders = await getOrder(token)
      setOrderItems(orders)
    }

    fetchOrders()
    loadContractCodes()
    return () => {
      setIsAddressAdded(false)
    }
  }, [orderId, token, isAddressAdded])

  return (
    <div className="container">
      {isSelectAddressModalOpen ? (
        <EditAddressModal
          supplierIds={orderItems.map((x) => x.Supplier.Id)}
          isOpen={isSelectAddressModalOpen}
          setIsSelectAddressModalOpen={setIsSelectAddressModalOpen}
          userData={userData}
          setSelectedAddress={setSelectedAddress}
          showAddAddressModal={() => setIsAddAddressModalOpen(true)}
          isAddressAdded={isAddressAdded}
        />
      ) : null}
      {isAddAddressModalOpen ? (
        <AddAddressModal
          isOpen={isAddAddressModalOpen}
          onClose={() => setIsAddAddressModalOpen(false)}
          saveCallback={() => setIsAddressAdded(true)}
        />
      ) : null}
      <header className="blue-header">
        <a href={`${window.location.origin}/marketplace`}>
          <img src={`${blobUrl}/raiven/raiven-marketplace-white-black.png`} alt="raiven logo" style={{ width: 180, height: 20 }} />
        </a>
      </header>
      <section className="order-wrapper">
        <div className="details-wrapper">
          <div style={{ display: 'inline-block', marginBottom: 24 }}>
            <a href={`${redirectUrl}?requestId=${requestId}`} className="back-to-requests">
              <FontAwesomeIcon
                icon={faChevronLeft}
                style={{ fontWeight: '400', fontSize: '16px', lineHeight: '16px', color: 'rgba(0, 0, 0, 0.25)' }}
              />
              <span>Back to Request</span>
            </a>
          </div>
          {orderItems.length > 0
            ? orderItems.map((item, i) =>
                item.Items.length > 0 ? (
                  <OrderEditItem
                    key={item.Id}
                    index={i}
                    userData={userData}
                    item={item}
                    contractCodes={contractCodes}
                    handleEditOrder={handleEditOrder}
                    handleInputBlur={handleInputBlur}
                    handleRemoveItem={handleRemoveItem}
                    setShowWarningMessage={setShowWarningMessage}
                    onSelect={onSelect}
                    hasOneItemLeft={hasOneItemLeft}
                  />
                ) : null
              )
            : null}
        </div>
        <div>
          <div className="summary-wrapper">
            <div className="summary-header">
              <p>Request #{orderId}</p>
            </div>
            <div style={{ borderBottom: '1px solid #DADDE1', width: '100%' }}>
              <div style={{ display: 'flex', flexDirection: 'column', gap: 8, padding: '16px 12px' }}>
                {orderItems.length > 0
                  ? orderItems.map((item, i) => (
                      <div key={item.Id} style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
                        <img
                          src={`${blobUrl}/${item.Supplier.SquareLogo}`}
                          alt="supplier square logo"
                          style={{ width: 20, height: 20, borderRadius: 4 }}
                        />
                        <p>
                          {item.Items.length} {item.Items.length === 1 ? 'item' : 'items'}
                        </p>
                        <p style={{ flex: 1, textAlign: 'right' }}>{generateSubtotal(item.Items)}</p>
                      </div>
                    ))
                  : null}
              </div>
            </div>
            <div style={{ borderBottom: '1px solid #DADDE1', width: '100%' }}>
              <div style={{ padding: '16px 12px' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 4 }}>
                  <p
                    style={{
                      fontFamily: 'Rubik',
                      fontStyle: 'normal',
                      fontWeight: 400,
                      fontSize: 12,
                      lineHeight: '16px',
                      display: 'flex',
                      alignItems: 'center',
                      color: 'rgba(0, 0, 0, 0.5)',
                    }}
                  >
                    Shipping address
                  </p>
                  <button
                    style={{
                      margin: 0,
                      padding: 0,
                      background: 'white',
                      color: '#2244FD',
                      fontFamily: 'Rubik',
                      fontStyle: 'normal',
                      fontWeight: 500,
                      fontSize: 12,
                      lineHeight: '16px',
                    }}
                    onClick={() => {
                        postAnalytics('pm-marketplace-editOrder-changeAddress-click', {orderId: orderId, currentAddress: orderItems[0].Shipping})
                        setIsSelectAddressModalOpen(true)
                      }
                    }
                  >
                    Change
                  </button>
                </div>
                {selectedAddress ? (
                  <div
                    style={{
                      fontFamily: 'Rubik',
                      fontStyle: 'normal',
                      fontWeight: 400,
                      fontSize: 14,
                      lineHeight: '20px',
                    }}
                  >
                    <h5>{selectedAddress.Name || ''}</h5>
                    <p>
                      {selectedAddress.Street1}, {selectedAddress.Street2}
                    </p>
                    <p>
                      {selectedAddress.City}, {selectedAddress.State} {selectedAddress.PostalCode}
                    </p>
                    {selectedAddress && selectedAddress.DeliverTo2 && <span className="address-id">ID #{selectedAddress.DeliverTo2}</span>}
                  </div>
                ) : null}
                {!selectedAddress && orderItems && orderItems.length > 0 ? (
                  <div
                    style={{
                      fontFamily: 'Rubik',
                      fontStyle: 'normal',
                      fontWeight: 400,
                      fontSize: 14,
                      lineHeight: '20px',
                    }}
                  >
                    <h5>{orderItems[0].Shipping.Name || ''}</h5>
                    <p>
                      {orderItems[0].Shipping.Street1}, {orderItems[0].Shipping.Street2}
                    </p>
                    <p>
                      {orderItems[0].Shipping.City}, {orderItems[0].Shipping.State} {orderItems[0].Shipping.PostalCode}
                    </p>
                    {orderItems[0] && orderItems[0].Shipping && orderItems[0].Shipping.DeliverTo2 && (
                      <span className="address-id">
                        {orderItems[0].Shipping.DeliverTo2 ? `ID #${orderItems[0].Shipping.DeliverTo2}` : null}
                      </span>
                    )}
                  </div>
                ) : null}
              </div>
            </div>
            <div style={{ width: '100%' }}>
              <div style={{ padding: '16px 12px' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 16 }}>
                  <p>Subtotal</p>
                  <p className="subtotal-summary">{calculateListPriceSum(orderItems)}</p>
                </div>
                <button className="save-button" onClick={() => {
                            postAnalytics('pm-marketplace-editOrder-saveChanges-click', {orderId: orderId})
                            saveAndUpdateOder()
                          }
                        }
                      >
                  Save changes
                </button>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginTop: 18,
                    background: 'white',
                  }}
                >
                  <a href={`${redirectUrl}?requestId=${requestId}`} className="cancel-button"
                    onClick={async (event) => {
                          event.preventDefault();
                          await postAnalytics('pm-marketplace-editOrder-cancel-click', {orderId: orderId})
                          window.location.href = `${redirectUrl}?requestId=${requestId}`;
                        }
                      }
                    >
                    Cancel
                  </a>
                </div>
              </div>
            </div>
          </div>
          {showWarningMessage ? (
            <div style={{ display: 'flex', alignItems: 'center', gap: 6, marginTop: 16 }}>
              <FontAwesomeIcon
                icon={faWarning}
                style={{
                  fontWeight: '400',
                  fontSize: '16px',
                  lineHeight: '16px',
                  color: '#FFAB00',
                }}
              />
              <p
                style={{
                  color: '#FFAB00',
                  fontFamily: 'Rubik',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  fontSize: 12,
                  lineHeight: '16px',
                }}
              >
                Order must contain at least 1 item
              </p>
            </div>
          ) : null}
        </div>
      </section>
    </div>
  )
}

export default OrderEdit
