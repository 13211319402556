import React, { useState, useEffect, Fragment } from 'react'
import _ from 'lodash'
import { Link } from 'react-router-dom'

import { Search } from '../../../../lib/@q-core/react-components'
import { Wrapper } from '../../components'
import { safeLocalStorage, getActiveCorpSettings, getValidStores } from '../../../utils/safeStorage'
import { jsonFetch } from '../../../utils/fetch2'
import { getSupplierContractTiles, postCorporateOfficeContract } from '../../apis'
import { SupplierGrid, SupplierInfoCard, SubmittedOrders } from './components'
import AddAccountModal from './addAccountModal'
import { showInfoCard, handleSearchSubmit, renderQplaceSuppliers, setOrders } from './helpers'
import FeatureStyles from './styles'
import Loader from '../../components/Loader'
import getDefaultCheckout from '../../../utils/getDefaultCheckout'
import { useFlags } from 'launchdarkly-react-client-sdk'
import postAnalytics from '../../../utils/postAnalytics'
import { TempUnifiNotification } from './components/SupplierGrid/tempUnifiNotification';

const { MarketplaceContainer, AddAccountModalContainer } = FeatureStyles

const isBrowser = typeof window !== 'undefined' && window.env
const blobUrl = isBrowser ? window.env.blobUrl : process.env.RAZZLE_RUNTIME_BLOB_URL
const harmonyRootUrl = isBrowser ? window.env.harmonyRootUrl : process.env.RAZZLE_RUNTIME_HARMONY_ROOT_URL

const Marketplace = (props) => {
  const featureFlags = useFlags()
  console.log('Marketplace :: featureFlags :: ', {...featureFlags})
  const user = safeLocalStorage.getJSONItem('userData')
  let defaultCheckout = props.defaultCheckout || getDefaultCheckout(user)
  const [searchTerm, setSearchTerm] = useState('')

  const [showInfo, setShowInfo] = useState(false)
  const [supplierInfo, setSupplierInfo] = useState(null)
  const [stores, setStores] = useState()
  const [suppliers, setSuppliers] = useState(safeLocalStorage.getJSONItem('suppliers'))
  const [recentOrders, setRecentOrders] = useState(null)
  const [ckMethod] = useState(defaultCheckout)
  const [showAll] = useState(false)
  const [addAccountModal, setAddAccountModal] = useState(false)
  const [errors, setErrors] = useState([])
  const [supplierWithPunchout, setSupplierWithPunchout] = useState([])
  const [supplierWithoutPunchout, setSupplierWithoutPunchout] = useState([])

  const state = {
    recentOrders,
    setRecentOrders,
    searchTerm,
    setSearchTerm,
    showInfo,
    setShowInfo,
    supplierInfo,
    setSupplierInfo,
    suppliers,
    setAddAccountModal,
    addAccountModal,
    setErrors,
    errors,
  }

  useEffect(() => {
    renderQplaceSuppliers({ user, setSuppliers })
    setOrders({ user, setRecentOrders, showAll })
    setStores(getValidStores())
    postAnalytics('pm-homePage-initialVisit', window.location.origin)
  }, [])

  useEffect(() => {
    if (suppliers && suppliers.length > 0) {
      organizeSupplierData(suppliers)
    }
  }, [suppliers])

  const addNewSupplier = async (contractName, accountNumber, label) => {
    const user = safeLocalStorage.getJSONItem('userData')
    const activeNetworkId = user && user.activeNetworkId
    let userToken = user.Network[user.activeNetworkIndex].UserToken
    if (contractName) {
      await postCorporateOfficeContract(userToken, activeNetworkId, contractName, accountNumber, label)
      props.history.push('/manage')
    }
  }

  const refreshStoresInStorage = async () => {
    const harmonyStoresUrl = `${harmonyRootUrl}/api/v2/stores`
    const cacheTimeInSeconds = 60 * 60 * 12 // seconds in a minute X minutes in an hour X hours to cache
    let stores = await jsonFetch(harmonyStoresUrl, {
      method: 'GET',
      headers: { 'Cache-Control': `max-age=${cacheTimeInSeconds}` },
    })
    safeLocalStorage.setItem('stores', JSON.stringify(stores))
  }

  useEffect(() => {
    if (
      props.history.location &&
      props.history.location.state &&
      props.history.location.state.search &&
      props.history.location.state.search.routeFrom === 'Manage'
    ) {
      setAddAccountModal(true)
    }
    refreshStoresInStorage()
  }, [])

  const organizeSupplierData = (suppliers) => {
    const punchoutSuppliers = []
    const nonPunchoutSuppliers = []
    suppliers.forEach((supplier) => {
      let ContractsWithPunchout = []
      let ContractsWithoutPunchout = []
      let objWithPunchout = { ...supplier }
      let objWithoutPunchout = { ...supplier }
      if(supplier.Contracts) {
        supplier.Contracts.forEach((contract) => {
          if (contract.CanPunchOut) {
            ContractsWithPunchout.push(contract)
          } else {
            ContractsWithoutPunchout.push(contract)
          }
        })
        objWithPunchout.Contracts = [...ContractsWithPunchout]
        objWithoutPunchout.Contracts = [...ContractsWithoutPunchout]
        if (ContractsWithPunchout.length > 0) {
          punchoutSuppliers.push({...objWithPunchout})
        }
        if (ContractsWithoutPunchout.length > 0) {          
          nonPunchoutSuppliers.push({...objWithoutPunchout})
        }
      }
    })
    setSupplierWithPunchout(punchoutSuppliers)
    setSupplierWithoutPunchout(nonPunchoutSuppliers)
  }

  return (
    <Wrapper {...props}>
      {stores && suppliers ? (
        <>
        <TempUnifiNotification>
        </TempUnifiNotification> 
        <MarketplaceContainer>
          <section className="q-place-container">
            {user && user.activeCorpSettings.FederatedSearchEnabled && (
              <div className="eql-search">
                <Search
                  className="eql-search-comp"
                  placeholder="Search your suppliers..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  onSubmit={() => handleSearchSubmit(searchTerm, props)}
                />
              </div>
            )}
            {suppliers && (
              <Fragment>
                <Link
                  to="/manage"
                  className="manage-link"
                  onClick={() => {
                    postAnalytics('pm-home-catalogs-click-manageAccounts')
                  }}>
                  <p className="manageLink">Manage accounts</p>
                </Link>
                <SupplierGrid
                  // suppliers={_.filter(suppliers, { HasPunchOut: true })}
                  suppliers={supplierWithPunchout}
                  type="catalog"
                  showInfoCard={(id, CompanyContractId) => showInfoCard(id, state, CompanyContractId)}
                  setAddAccountModal={() => setAddAccountModal(true)}
                  supplierPunchout={(id, accountNum) => {
                    // setTimeout(() => {
                    //     props.history.push('/test')
                    // }, 10000)
                    props.history.push(`/punchout?supplier=${id}&accountNumber=${accountNum}`)
                  }}
                />
                <SupplierGrid
                  // suppliers={_.filter(suppliers, { HasPunchOut: false })}
                  suppliers={supplierWithoutPunchout}
                  type="info"
                  showInfoCard={(id, CompanyContractId) => showInfoCard(id, state, CompanyContractId)}
                />
              </Fragment>
            )}
            {showInfo && <div className="card-overlay-bg" />}
            {showInfo && supplierInfo && (
              <SupplierInfoCard supplier={supplierInfo} showCard={showInfo} hideCard={() => setShowInfo(false)} />
            )}
            {ckMethod !== 'spendbridge' && (
              <SubmittedOrders
                recentOrders={recentOrders}
                setOrders={() => setOrders()}
                user={user}
                setRecentOrders={setRecentOrders}
              />
            )}
          </section>
          <section className="nav-sidebar">
            {/* <ul className="nav-icons">
                            {/* <li className="invite-icon" onClick={() => {}}>
                                <i className="far fa-user-plus" />
                                <p>Invite</p>
                            </li> */}
            {/* }    <li className="orders-icon" onClick={() => props.history.push('/orders')}>
                                <i className="far fa-truck" />
                                <p>Orders</p>
                            </li>
                            <li className="quotes-icon" onClick={() => props.history.push('/quotes')}>
                                <i className="far fa-file-invoice-dollar" />
                                <p>Quotes</p>
                            </li>
                        </ul> */}
            {/*<a href={props.hasExtension ? '/stores/covid-19-essentials' : bvfExtensionLink} onClick={handleProcurementBannerClick}>
              <img
                className="ad-image"
                alt="ad"
                src={
                  blobUrl + (props.hasExtension ? '/procurement/images/Procurement-Banner-CovidStore.png' : '/procurement/images/Procurement-Banner-GetBVF.png')
                }
              />
            </a>*/}
            {getActiveCorpSettings().StoresEnabled && stores && (
              <div className="stores-nav easeIn">
                <h3>Stores</h3>
                <ul>
                  {stores.map((store) => (
                    <li
                      key={store.storeName}
                      className={`${store.storeName}-button`}
                      onClick={() => props.history.push(`/stores/${store.storeName}`)}
                    >
                      <img alt="store" src={blobUrl + store.imageUrl} />
                      <p>{store.title}</p>
                    </li>
                  ))}
                </ul>
              </div>
            )}
            {addAccountModal && (
              <AddAccountModalContainer>
                <AddAccountModal setAddAccountModal={setAddAccountModal} addNewSupplier={addNewSupplier} />
              </AddAccountModalContainer>
            )}
          </section>
        </MarketplaceContainer>
        </>
      ) : (
        <div className="overlay-loader">
          <Loader />
        </div>
      )}
    </Wrapper>
  )
}

export default Marketplace
