import React, { useEffect, useState, useContext } from 'react'
import { useLocation } from 'react-router-dom'
import Button from '../Button'
import RequestModal from '../RequestModal'
import styles from './style.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Tooltip from '../Tooltip'
import {
  faCircleCheck,
  faHexagonXmark,
  faCircleUser,
  faCheck,
  faXmark,
  faUserEdit,
  faUserPen,
} from '@fortawesome/pro-regular-svg-icons'
import { getRequestDetails } from '../../features/Approvals/api'
import { MobileLayout } from '../../App'
import DateFormat from '../../../utils/dateTimeFormat'
import postAnalytics from '../../../utils/postAnalytics'
import RequestAttachments from '../RequestAttachments'

const isBrowser = typeof window !== 'undefined' && window.env
const blobUrl = isBrowser ? window.env.blobUrl : process.env.RAZZLE_RUNTIME_BLOB_URL

function RequestDetailsModal({ selectedRequest, onClose, isAdmin, isApprover = false, activeTab, onChange }) {
  const isMobile = useContext(MobileLayout)
  const location = useLocation()

  const [requestDetails, setRequestDetails] = useState([])
  const [orderTotal, setOrderTotal] = useState(null)
  const [disableModal, setDisableModal] = useState(false)

  const setStatusColor = (status) => {
    switch (status) {
      case 'Pending':
        return '#84FFFF'
      case 'Approved':
        return '#B9F6CA'
      case 'Rejected':
        return '#FF9E80'
      default:
        return '#84FFFF'
    }
  }

  const selectIcon = (status) => {
    switch (status) {
      case 'Approved':
        return <FontAwesomeIcon icon={faCircleCheck} color="#009C1B" />
      case 'Rejected':
        return <FontAwesomeIcon icon={faHexagonXmark} color="#DE0400" />
      default:
        return null
    }
  }

  const statusColor = setStatusColor(selectedRequest.status)

  const setStatusMessage = (status) => {
    switch (status) {
      case 'Pending':
        return 'Pending approval'
      case 'Approved':
        return 'Approved'
      case 'Rejected':
        return 'Rejected'
      default:
        return 'Pending approval'
    }
  }

  const selectStatusIcon = (status) => {
    switch (status) {
      case 'Approved':
        return <FontAwesomeIcon icon={faCircleCheck} style={{ color: '#00C853', fontSize: 16 }} />
      case 'Rejected':
        return <FontAwesomeIcon icon={faHexagonXmark} style={{ color: '#FF3D00', fontSize: 16 }} />
      case 'Reassigned':
        return <FontAwesomeIcon icon={faUserPen} style={{ color: 'rgba(0, 0, 0, 0.25)', fontSize: 16 }} />
      default:
        return <FontAwesomeIcon icon={faCircleUser} style={{ color: 'rgba(0, 0, 0, 0.25)', fontSize: 16 }} />
    }
  }

  const showApproversByLevel = (approvalStep) => {
    const userApproved = approvalStep.completedByUser

    let approverLabel = userApproved ? userApproved : approvalStep.approvers.map((approver) => approver.name).join(', ')

    if (approvalStep.approvalResult === 'Reassigned') {
      approverLabel = <strike>{approverLabel}</strike>
    }
    return (
      <div>
        <p className={styles.approvers}>{approverLabel}</p>
        {approvalStep.stepCompleted === true && (
          <div>
            <p className={styles.approvalStepInfo}>
              {approvalStep.approvalResult} {DateFormat.toLocalDateTimeWithTimezone(approvalStep.completedOn)}
            </p>
            <p className={styles.approvalStepComment}>{approvalStep.comment}</p>
          </div>
        )}
      </div>
    )
  }

  const handleApprovalChainSteps = (approvalChainSteps) => {
    const approvalLevels = approvalChainSteps.map((approvalStep) => {
      return (
        //adjusting the gab for the reasigned image so it lines up with the rest
        <div
          key={approvalStep.approvalChainId}
          style={{ display: 'flex', gap: approvalStep.approvalResult === 'Reassigned' ? 6 : 10, marginBottom: 12 }}
        >
          {selectStatusIcon(approvalStep.approvalResult)}
          {showApproversByLevel(approvalStep)}
        </div>
      )
    })

    return approvalLevels
  }

  const getSubtotalPerRequest = (request) => {
    let subtotal = 0
    request.forEach((request) => {
      subtotal += request.UnitPrice * request.Quantity
    })

    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    }).format(Number(subtotal))
  }

  const handleOnChange = (modelName, selectedRequest) => {
    setDisableModal(true)
    onChange(modelName, selectedRequest)
  }

  useEffect(() => {
    async function fetchData() {
      let requestDetails = await getRequestDetails({
        token: localStorage.getItem('token'),
        orderId: selectedRequest.externalId,
      })
      const sumTotalAmount = requestDetails.reduce((accumulator, object) => {
        return accumulator + object.TotalAmount
      }, 0)
      setRequestDetails(requestDetails)
      setOrderTotal(sumTotalAmount)
    }
    fetchData()
  }, [])

  return (
    <RequestModal
      width={900}
      reactToOutsideClick={!disableModal}
      onClose={() => onClose('hideRequestDetailsModal')}
      header={
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
          <h3 className={styles.requestHeaderTitle}>Request</h3>
          {!isMobile &&
          (selectedRequest.isCurrentApprover || isAdmin) &&
          ['all', 'approver'].includes(activeTab) &&
          selectedRequest.status === 'Pending' &&
          requestDetails.length > 0 &&
          requestDetails[0].EditUrl != null ? (
            <a
              className={styles.editOrderLink}
              onClick={() => {
                postAnalytics('pm-requests-detailModal-editOrder-click', { editurl: requestDetails[0].EditUrl })
              }}
              href={
                requestDetails.length > 0
                  ? `${decodeURIComponent(requestDetails[0].EditUrl)}&redirectUrl=${encodeURIComponent(
                      location.pathname
                    )}&requestId=${selectedRequest.id}`
                  : ''
              }
            >
              Edit order
            </a>
          ) : null}
        </div>
      }
      awaitingActions={isApprover && selectedRequest.isCurrentApprover && selectedRequest.status === 'Pending'}
    >
      <div
        style={{
          display: 'flex',
          flex: 1,
          flexDirection: isMobile ? 'column' : 'row',
          overflowY: isMobile ? 'scroll' : 'hidden',
        }}
      >
        <div
          style={{
            width: isMobile ? '100%' : 300,
            borderRight: '1px solid #DADDE1',
            overflowY: 'unset',
          }}
        >
          <div className={styles.requestorApproverContainer} style={{ height: '100%', overflowY: 'scroll' }}>
            <div
              style={{
                backgroundColor: statusColor,
                padding: 16,
                display: 'flex',
                gap: 8,
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {selectIcon(selectedRequest.status)}
              <h4
                style={{
                  color: '#000000',
                  fontFamily: 'Rubik',
                  fontStyle: 'normal',
                  fontWeight: 600,
                  fontSize: 16,
                  lineHeight: '20px',
                }}
              >
                {setStatusMessage(selectedRequest.status)}
              </h4>
            </div>
            <div className={styles.requestorSectionContainer}>
              <div style={{ marginBottom: 16 }}>
                <p
                  style={{
                    color: 'rgba(0, 0, 0, 0.5)',
                    fontFamily: 'Poppins',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    fontSize: 14,
                    lineHeight: '20px',
                  }}
                >
                  Requester
                </p>
                <p
                  style={{
                    fontFamily: 'Rubik',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    fontSize: 14,
                    lineHeight: '20px',
                    color: '#000000',
                  }}
                >
                  {selectedRequest.requester}
                </p>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 8,
                  flex: 1,
                }}
              >
                {selectedRequest.approvers ? (
                  <div>
                    <p className={styles.detailsLabel}>
                      Approver
                      {Array.isArray(selectedRequest.approvers) && selectedRequest.approvers.length > 1 ? 's' : ''}
                    </p>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'flex-end',
                        justifyContent: 'space-between',
                      }}
                    >
                      <div className={styles.detailsValue} style={{ textTransform: 'none' }}>
                        {handleApprovalChainSteps(selectedRequest.approvalChainSteps)}
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
            <div className={styles.shippingDetails} style={{ flex: isMobile ? 'none' : 1 }}>
              <div style={{ marginBottom: 8 }}>
                <p className={styles.detailsLabel}>Shipping address</p>
                {requestDetails && requestDetails.length > 0 && (
                  <div>
                    <p className={styles.detailsValue}>
                      {requestDetails[0].Shipping.Name && (
                        <span>
                          {requestDetails[0].Shipping.Name} <br />
                        </span>
                      )}
                      {requestDetails[0].Shipping.Street1} <br />
                      {requestDetails[0].Shipping.Street2 && (
                        <span>{requestDetails[0].Shipping.Street2}<br /></span>
                      )}
                      {requestDetails[0].Shipping.City}, {requestDetails[0].Shipping.State}{' '}
                      {requestDetails[0].Shipping.PostalCode}
                      {requestDetails[0].Shipping.DeliverTo2 && (
                        <span className={styles.detailsValueJobNumber}>
                          <br />
                          ID# {requestDetails[0].Shipping.DeliverTo2}
                        </span>
                      )}
                    </p>
                  </div>
                )}
              </div>
              <div>
                <p className={styles.detailsLabel}>Address ID</p>
                <p className={styles.detailsValue} style={{ marginBottom: 16 }}>
                  {requestDetails.length > 0 ? requestDetails[0].Shipping.ExternalId : ''}
                </p>
              </div>

              <div style={{ marginBottom: 8 }}>
                <p className={styles.detailsLabel}>Submitted</p>
                <p className={styles.detailsValue}>
                  {requestDetails &&
                    requestDetails.length > 0 &&
                    DateFormat.toLocalDateTimeWithTimezone(requestDetails[0].CreatedOn)}
                </p>
              </div>
              <div>
                <p className={styles.detailsLabel}>Request</p>
                <p className={styles.detailsValue}>{selectedRequest.externalId}</p>
              </div>
              <RequestAttachments
                isAdmin={isAdmin}
                isApprover={isApprover}
                status={selectedRequest.status}
                orderId={selectedRequest.externalId}
                attachments={requestDetails[0]?.Attatchments}
              />
            </div>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            minHeight: 'auto',
            paddingBottom: isMobile ? '114px' : 0,
            overflowY: isMobile ? 'initial' : 'scroll',
          }}
        >
          <div className={styles.itemsContainer}>
            {requestDetails &&
              requestDetails.length > 0 &&
              requestDetails.map((request) => {
                return isMobile ? (
                  <div key={`${request.externalId}-${request.CreatedOn}`} style={{ marginBottom: 32 }}>
                    <div
                      style={{
                        display: 'flex',
                        gap: 8,
                        alignItems: 'center',
                        marginBottom: 8,
                      }}
                    >
                      <img
                        style={{ width: 24, height: 24, borderRadius: 4 }}
                        alt="supplier"
                        src={`${blobUrl}/${request ? request.Supplier.SquareLogo : ''}`}
                      />
                      <h4 className={styles.supplierName}>{request ? request.Supplier.Name : ''}</h4>
                    </div>
                    <div style={{ display: 'flex', gap: 24, marginBottom: 8 }}>
                      <p className={styles.requestLabel}>
                        Subtotal{' '}
                        <span className={styles.price} style={{ marginLeft: 8 }}>
                          {request.Items.length > 0 ? getSubtotalPerRequest(request.Items) : ''}
                        </span>
                      </p>
                      <p className={styles.requestLabel}>
                        PO#{' '}
                        <span className={styles.price} style={{ marginLeft: 8 }}>
                          {request ? request.PurchaseOrderNumber : ''}
                        </span>
                      </p>
                    </div>
                    {request &&
                      request.Items.length > 0 &&
                      request.Items.map((item) => {
                        return (
                          <div key={item.Id} className={styles.mobileRequestItem}>
                            <div className={styles.mobileProductDetails}>
                              <img
                                style={{ width: 48, height: 48, borderRadius: '4px' }}
                                alt="product"
                                src={`https:${item.ImageUrl || '/images/product-default.png'}`}
                                onError={(e) => {
                                  e.currentTarget.src = '/images/product-default.png'
                                }}
                              />
                              <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
                                <Tooltip content={item.PartName} direction="top">
                                  <p
                                    className={styles.productName}
                                    style={{
                                      WebkitBoxOrient: 'vertical',
                                    }}
                                  >
                                    {item.PartName}
                                  </p>
                                </Tooltip>
                                <div style={{ display: 'flex', gap: '8px' }}>
                                  <p className={styles.productBrand}>{item.ManufacturerName}</p>
                                  <p
                                    className={styles.productBrand}
                                    style={{ color: 'rgba(0, 0, 0, 0.5)', inlineSize: '70px' }}
                                  >
                                    {item.ManufacturerPartId}
                                  </p>
                                </div>
                                {item.ContractCodeName ? (
                                  <p className={styles.contractCodeLabel}>
                                    Contract code:{' '}
                                    <span>
                                      {item.ContractCodeName}
                                      {item.ContractCodeDescription ? ` - ${item.ContractCodeDescription}` : null}
                                    </span>
                                  </p>
                                ) : null}
                              </div>
                            </div>
                            <div className={styles.mobileProductOrderDetails}>
                              <p className={styles.productPrice}>
                                <span className={styles.price}>
                                  {new Intl.NumberFormat('en-US', {
                                    style: 'currency',
                                    currency: 'USD',
                                  }).format(Number(item.UnitPrice))}
                                </span>{' '}
                                <span
                                  style={{
                                    fontFamily: 'Rubik',
                                    fontWeight: '400',
                                    fontSize: '14px',
                                    lineHeight: '20px',
                                    color: 'rgba(0, 0, 0, 0.5)',
                                  }}
                                >
                                  /ea
                                </span>
                              </p>
                              <p className={styles.quantity} style={{ fontFamily: 'Poppins' }}>
                                Qty <span className={styles.quantityValue}>{item.Quantity}</span>
                              </p>
                            </div>
                          </div>
                        )
                      })}
                  </div>
                ) : (
                  <div className={styles.itemsDetailContainer} key={`${request.externalId}-${request.CreatedOn}`}>
                    <div
                      style={{
                        display: 'flex',
                        gap: 8,
                        alignItems: 'center',
                        marginBottom: 8,
                        paddingLeft: 24,
                        paddingRight: 24,
                      }}
                    >
                      <img
                        style={{ width: 24, height: 24 }}
                        alt="supplier"
                        src={`${blobUrl}/${request ? request.Supplier.SquareLogo : ''}`}
                      />
                      <h4 className={styles.supplierName}>{request ? request.Supplier.Name : ''}</h4>
                    </div>
                    <div style={{ display: 'flex', gap: 24, marginBottom: 8, paddingLeft: 24, paddingRight: 24 }}>
                      <p className={styles.requestLabel}>
                        Subtotal{' '}
                        <span className={styles.price} style={{ marginLeft: 8 }}>
                          {request.Items.length > 0 ? getSubtotalPerRequest(request.Items) : ''}
                        </span>
                      </p>
                      <p className={styles.requestLabel}>
                        PO#{' '}
                        <span className={styles.price} style={{ marginLeft: 8 }}>
                          {request ? request.PurchaseOrderNumber : ''}
                        </span>
                      </p>
                    </div>
                    <div style={{ marginBottom: 16, paddingRight: 16, paddingLeft: 16 }}>
                      {request &&
                        request.Items.length > 0 &&
                        request.Items.map((item) => {
                          return (
                            <div key={item.Id} className={styles.requestItem}>
                              <img
                                style={{ width: 60, height: 60 }}
                                alt="product"
                                src={`https:${item.ImageUrl || '/images/product-default.png'}`}
                                onError={(e) => {
                                  e.currentTarget.src = '/images/product-default.png'
                                }}
                              />
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  flex: 1,
                                }}
                              >
                                <Tooltip content={item.PartName} direction="top">
                                  <p
                                    className={styles.productName}
                                    style={{
                                      WebkitBoxOrient: 'vertical',
                                    }}
                                  >
                                    {item.PartName}
                                  </p>
                                </Tooltip>
                                <p className={styles.productBrand}>
                                  {item.ManufacturerName} {item.ManufacturerPartId}
                                </p>
                                {item.ContractCodeName ? (
                                  <p className={styles.contractCodeLabel}>
                                    Contract code:{' '}
                                    <span>
                                      {item.ContractCodeName}
                                      {item.ContractCodeDescription ? ` - ${item.ContractCodeDescription}` : null}
                                    </span>
                                  </p>
                                ) : null}
                              </div>
                              <div style={{ display: 'flex', flexDirection: 'column', height: 60 }}>
                                <p className={styles.productPrice}>
                                  <span className={styles.price}>
                                    {new Intl.NumberFormat('en-US', {
                                      style: 'currency',
                                      currency: 'USD',
                                    }).format(Number(item.UnitPrice))}
                                  </span>{' '}
                                  <span>/ea</span>
                                </p>
                                <p className={styles.quantityLabel}>
                                  Qty <span className={styles.quantityValue}>{item.Quantity}</span>
                                </p>
                              </div>
                            </div>
                          )
                        })}
                    </div>
                  </div>
                )
              })}
          </div>
          <div className={styles.requestFooter}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                flexWrap: isMobile && 'wrap',
                gap: isMobile && '8px',
              }}
            >
              <p className={styles.totalLabel}>Total</p>
              {(selectedRequest.isCurrentApprover || isAdmin) &&
                ['all', 'approver'].includes(activeTab) &&
                selectedRequest.status === 'Pending' && (
                  <div className={styles.actionsContainer}>
                    {isMobile ? (
                      <div
                        style={{
                          width: '32px',
                          height: '32px',
                          borderRadius: '16px',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                        onClick={() => handleOnChange('showReassignModal', selectedRequest)}
                      >
                        <FontAwesomeIcon
                          icon={faUserEdit}
                          style={{
                            fontSize: 16,
                            color: 'rgba(0, 0, 0, 0.25)',
                            fontWeight: '400',
                            lineHeight: '16px',
                            width: '32px',
                          }}
                        />
                      </div>
                    ) : (
                      <Button
                        variant="default"
                        onClick={() => {
                          handleOnChange('showReassignModal', selectedRequest)
                        }}
                        text="Reassign"
                        size="md"
                      />
                    )}
                    <Button
                      variant="reject"
                      onClick={() => handleOnChange('showRejectModal', selectedRequest)}
                      fullWidth
                      text="Reject"
                      size="md"
                      icon={<FontAwesomeIcon icon={faXmark} color="#DE0400" style={{ fontSize: 16 }} />}
                      styles={{
                        paddingTop: isMobile && '8px',
                        paddingBottom: isMobile && '8px',
                        fontFamily: isMobile && 'Rubik',
                        fontWeight: isMobile && '500',
                        fontSize: isMobile && '14px',
                        lineHeight: isMobile && '20px',
                        letterSpacing: isMobile && '0.01em',
                      }}
                    />
                    <Button
                      variant="approve"
                      onClick={() => handleOnChange('approveRequest', selectedRequest)}
                      fullWidth
                      text="Approve"
                      size="md"
                      icon={<FontAwesomeIcon icon={faCheck} color="#009C1B" style={{ fontSize: 16 }} />}
                      styles={{
                        paddingTop: isMobile && '8px',
                        paddingBottom: isMobile && '8px',
                        fontFamily: isMobile && 'Rubik',
                        fontWeight: isMobile && '500',
                        fontSize: isMobile && '14px',
                        lineHeight: isMobile && '20px',
                        letterSpacing: isMobile && '0.01em',
                      }}
                    />
                  </div>
                )}
              <p className={styles.totalAmount}>
                {orderTotal
                  ? new Intl.NumberFormat('en-US', {
                      style: 'currency',
                      currency: 'USD',
                    }).format(Number(orderTotal))
                  : ''}
              </p>
            </div>
          </div>
        </div>
      </div>
    </RequestModal>
  )
}

export default RequestDetailsModal
