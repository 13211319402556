import React from 'react'
import { useAuth0 } from "@auth0/auth0-react";
import './styles.css'
import { RLogo } from '../svg/raivenIcon'
import { removeUserData } from '../../../utils/localStorage';
import postAnalytics from '../../../utils/postAnalytics.js';

const NotFoundUserScreen = () => {
  const { logout, user } = useAuth0();

  const handleLogOut = () => {
    postAnalytics('pm-accountManage-accountCard-click-logout', {});

    let UserActivityEvent = new CustomEvent("UserActivityEvent", {
      detail: { activeNetwork: null },
    });
    if (document) document.dispatchEvent(UserActivityEvent);
    removeUserData();
    logout({ logoutParams: { returnTo: window.location.origin } });
  };

  return (
    <div className='noUserFound'>
        <div className='content'>
          <RLogo />
          <div className='textContent'>
            <h3>Hmm, your login wasn’t recognized</h3>
            <p className='mainText'>
            Verify that {user?.email ?? 'your email'} is the correct login you used to set up your Raiven account or email Raiven support at <a href="mailto:marketplace@raiven.com" className='email'>marketplace@raiven.com</a>.
            </p>
            <a onClick={handleLogOut} className='goBack'>Go back to Login</a>
            <div className='textFooter'>
              <span>Not a member yet?</span>
              <a href="https://www.raiven.com/learn-more">Learn more!</a>
            </div>
          </div>
        </div>
        <div className='aurora-footer' />
    </div>
  )
}

export default NotFoundUserScreen