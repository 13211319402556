import _ from 'lodash'
import { FormatNumber } from '../../../../lib/@q-core/raiven-utils'
import getDefaultCheckout from '../../../utils/getDefaultCheckout'
import { safeLocalStorage } from '../../../utils/safeStorage'
import { fetchData as apiFetch, getCartData, getSubmittedOrders, postAnalytics, postItemQuantity } from '../../apis'
import apiConfig from '../../apis/apiConfig'
import {hasRaivenCheckout} from '../../../hooks/useDefaultCheckout'

const isBrowser = typeof window !== 'undefined' && window.env
const apiKey = isBrowser ? window.env.apiKey : process.env.RAZZLE_RUNTIME_API_KEY
const apiRoot = isBrowser ? window.env.apiRoot : process.env.RAZZLE_RUNTIME_API_ROOT

export const ADDRESS_VERIFICATION_STATUS = {
  NO_UNVERIFIED: 0,
  ALL_UNVERIFIED: 1,
  SOME_UNVERIFIED: 2,
}

export const retrieveCartData = async ({ userData, setCart, setEqlCartCount }) => {
  let checkout = getDefaultCheckout(userData)
  let eqlCartCount = 0

  const cart = await getCartData({ userData }).then((cart) => {
    safeLocalStorage.setJSONItem('cart', cart)
    return cart
  })

  if (checkout === 'eql') {
    const result = await getEqlCart(userData)

    if (_.has(result, 'checkout.Count')) {
      eqlCartCount = result.checkout.Count
    }
  }

  setTimeout(() => {
    setCart(cart)
    setEqlCartCount(eqlCartCount)
  }, 2000)
}

export const getContractCodes = async (companyId) => {
  try {    
    const userData = safeLocalStorage.getJSONItem('userData')
    if(!hasRaivenCheckout(userData)){
      //do not want to show contract codes to users not in our approval system
     return [];
   } 
    
    const options = {
      ...apiConfig.getCompanyContractCodes,
      url: `${apiConfig.getCompanyContractCodes.url}${companyId}/ContractCodes`,
    }
    const response = await apiFetch(options)

    return response
  } catch (error) {
    return []
  }
}

export const setOrdersData = async ({ userData }) => {
  return await getSubmittedOrders({ userData }).then((recentOrders) => {
    safeLocalStorage.setJSONItem('recentOrders', recentOrders)
    return recentOrders
  })
}

const getEqlCart = async (userData) => {
  const checkoutUrl = `${apiRoot}/v1/Cart/EqualLevel/User/${userData.Network[userData.activeNetworkIndex].UserToken}`  
  const bearerToken = window.localStorage.getItem('token')
  const checkoutOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Ocp-Apim-Subscription-Key': `${apiKey}`,
      'Authorization': `bearer ${bearerToken}`,
    },
  }
  const checkout = await fetchData(checkoutUrl, checkoutOptions)
  if (checkout.Total) {
    checkout.Total = checkout.Total.replace('<span class="currency-usd" title="USD">', '').split('<')[0]
    checkout.dollars = checkout.Total.split('.')[0]
    checkout.cents = checkout.Total.split('.')[1]
    return { checkout: { ...checkout } }
  }
  return {
    checkout: {
      Count: 0,
      Total: 0,
      dollars: 0,
      cents: '00',
    },
  }
}

const fetchData = async (searchUrl, searchOptions) =>
  fetch(searchUrl, searchOptions)
    .then((response) => {
      if (response.ok) {
        return response.json()
      }
      return response
    })
    .then((data) => data)
    .catch((fetchError) => fetchError)

export const roundTotal = (total) => Math.round((total + Number.EPSILON) * 100) / 100

export const calcTotal = (price, quantity, options = { format: true }) => {
  let Price
  Price = price.toString().replace(/,/g, '') // a string, so convert it to number
  Price = parseFloat(Price, 10)
  const total = Price * quantity
  let formattedTotal = FormatNumber(total)

  if (options.format === false) {
    formattedTotal = total
  }

  return formattedTotal
}

export const subTotalPrice = (price) =>
  price.reduce((a, b) => Number(parseFloat(a).toFixed(2)) + Number(parseFloat(b).toFixed(2)))

export const subTotal = (items, { formatted = true, priceKey = 'ListPrice', quantityKey = 'Quantity' }) => {
  let subTotal = 0

  if (!_.isEmpty(items)) {
    _.map(items, (item) => {
      const itemTotal = calcTotal(item[priceKey], item[quantityKey], { format: false })
      subTotal += itemTotal
    })
  }

  let finalTotal = 0

  if (formatted === false) {
    finalTotal = FormatNumber.toFloat(subTotal)
  } else {
    finalTotal = FormatNumber(subTotal)
  }

  return finalTotal
}

export const handleUndoState = ({ supplierId, action, setUndo, undo }) => {
  const supplierIds = [...undo.supplierIds]
  const isInArray = _.includes(supplierIds, supplierId)

  if (action === 'add' && !isInArray) {
    supplierIds.push(supplierId)
  }

  if (action === 'remove' && isInArray) {
    _.pull(supplierIds, supplierId)
  }

  setUndo({
    popup: !_.isEmpty(supplierIds),
    supplierIds,
    active: undo.active,
  })
}

export const handleCheckout = ({ cart, history }) => {
  postAnalytics('market-cart-checkout', { cart })
  console.warn('handleCheckout :: updated cart with checks :: cart :: ', cart)

  history.push('/checkout')
}

export const calcTotalItems = (requisitions) => {
  let itemsTotal = 0
  requisitions.forEach((item) => {
    itemsTotal += item.Quantity
  })
  return itemsTotal
}
// export const setCartData = async
export const handleQuantityUpdate = async ({ supplierId, setCart, userData, item, unSetLoadingItem }) => {
  const accountNumberFromCartData = await getCartData({ userData })
  const accountNum = accountNumberFromCartData.CartRequisitions.filter((x) =>
    x.SupplierId === supplierId ? x.AccountNumber : null
  )[0].AccountNumber
  await postItemQuantity({
    item,
    partNumber: item.SupplierPartId,
    id: item.Id,
    supplierId,
    userData,
    AccountNumber: accountNum,
  }).then((cart) => {
    safeLocalStorage.setJSONItem('cart', cart)
    setCart(cart)
    unSetLoadingItem(item.Id)
  })
}

export const getFormattedPrice = (price) =>
  Number(parseFloat(price).toFixed(2)).toLocaleString('en', {
    minimumFractionDigits: 2,
  })

export const filtereCartRequisitions = (CartRequisitions = [], UnVerifiedForSuppliers = []) => {
  const filteredCartRequisitions = CartRequisitions.filter((req) => !UnVerifiedForSuppliers.includes(req.SupplierId))
  const updatedCartTotal = filteredCartRequisitions.reduce(
    (total, value) => total + Number(value.TotalAmount.replace(/,/g, '')),
    0
  )
  const updatedCartItemTotal = filteredCartRequisitions.reduce((total, value) => total + value.Requisitions.length, 0)
  return {
    filteredCartRequisitions,
    updatedCartTotal: getFormattedPrice(updatedCartTotal),
    updatedCartItemTotal,
  }
}

export const checkAddressVerificationStatus = (UnVerifiedForSuppliers = [], supplierIdsToCheck = []) => {
  let verificationStatus = ADDRESS_VERIFICATION_STATUS.NO_UNVERIFIED

  if (!(UnVerifiedForSuppliers || []).length) {
    verificationStatus = ADDRESS_VERIFICATION_STATUS.NO_UNVERIFIED
  } else if (supplierIdsToCheck.every((supplierId) => (UnVerifiedForSuppliers || []).includes(supplierId))) {
    verificationStatus = ADDRESS_VERIFICATION_STATUS.ALL_UNVERIFIED
  } else if (supplierIdsToCheck.some((supplierId) => (UnVerifiedForSuppliers || []).includes(supplierId))) {
    verificationStatus = ADDRESS_VERIFICATION_STATUS.SOME_UNVERIFIED
  }
  return verificationStatus
}

export const isVerifiedAddress = (addressOptions, cart, id) => {
  const suppliersInCart = (cart.CartRequisitions && cart.CartRequisitions.map((x) => x.SupplierId)) || []
  return isVerifiedAddressForSuppliers(addressOptions, suppliersInCart, id)
}

export const getAllItemsIds = (cart) => {
  const mapped = cart.CartRequisitions.map((item) =>
    item.Requisitions.map((requisition) => {
      return {
        SupplierId: item.Supplier.Id,
        AccountNumber: item.AccountNumber,
        SupplierPartNumber: requisition.SupplierPartId,
        Quantity: requisition.Quantity,
        Id: requisition.Id
      }
    })
  ).flat()

  return mapped.filter(
    (product, index, self) =>
      index ===
      self.findIndex(
        (p) =>
          p.SupplierId === product.SupplierId &&
          p.AccountNumber === product.AccountNumber &&
          p.SupplierPartNumber === product.SupplierPartNumber &&          
          p.Id === product.Id
      )
  )
}

export const isVerifiedAddressForSuppliers = (addressOptions, supplierIds, id) => {
  const selectedAddress = addressOptions.find((address) => id === address.Id)
  let verificationStatus = ADDRESS_VERIFICATION_STATUS.NO_UNVERIFIED
  if (selectedAddress) {
    verificationStatus = checkAddressVerificationStatus(selectedAddress.UnVerifiedForSuppliers, supplierIds)
  }
  return verificationStatus
}

export const getVerifiedCart = (cart = {}, addresses = {}) => {
  if (!cart.CartRequisitions || !addresses || !addresses.Shipping || !addresses.Billing) {
    return cart
  }
  const addressUnVerifiedForSuppliers = (addresses.Shipping.UnVerifiedForSuppliers || []).concat(
    addresses.Billing.UnVerifiedForSuppliers || []
  )

  const suppliersInCart = cart.CartRequisitions.map((x) => x.SupplierId)
  const verificationStatus = checkAddressVerificationStatus(addressUnVerifiedForSuppliers, suppliersInCart)
  const updatedCart = {
    ...cart,
    CartRequisitions: [...cart.CartRequisitions],
  }
  if (verificationStatus === ADDRESS_VERIFICATION_STATUS.ALL_UNVERIFIED) {
    updatedCart.CartRequisitions = []
    updatedCart.CartTotal = '0'
    updatedCart.CartItemTotal = 0
  } else if (verificationStatus === ADDRESS_VERIFICATION_STATUS.SOME_UNVERIFIED) {
    const { filteredCartRequisitions, updatedCartTotal, updatedCartItemTotal } = filtereCartRequisitions(
      updatedCart.CartRequisitions,
      addressUnVerifiedForSuppliers
    )
    updatedCart.CartRequisitions = filteredCartRequisitions
    updatedCart.CartTotal = updatedCartTotal.toString()
    updatedCart.CartItemTotal = updatedCartItemTotal
  }
  return updatedCart
}
