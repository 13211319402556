import React, { PureComponent, Fragment } from 'react'
import Header from '../../components/Header'
import ToastMessage from '../../components/ToastMessage'

import './styles.css'

const isBrowser = typeof window !== 'undefined' && window.env

const copyLinkToClipboard = () => {
  var copyText = document.getElementById('chrome-link')
  copyText.select()
  document.execCommand('copy')
}
export class OnBoarding extends PureComponent {
  constructor(props) {
    const profile = JSON.parse(window.localStorage.getItem('profile'))
    console.log('OnBoarding :: loading profile :: ', profile)
    super(props)
    this.state = {
      hidePassword: true,
      hasExtension: true,
      chromeLink: 'https://chrome.google.com/webstore/detail/qmerit-marketplace/iliobjcmbhjgdnlhinojfnbphecbekgo',
      profile,
    }
  }

  componentDidMount = () => {
    this.setState({
      isChrome: this.isChromeBrowser(),
      isIEedge: window.navigator.userAgent.indexOf('Edge') > -1,
      hasExtension: true,
    })
    this.checkIfExtensionInstalled()
  }

  componentDidUpdate = () => {
    if (isBrowser && !this.state.hasExtension) {
      setTimeout(() => {
        this.checkIfExtensionInstalled()
      }, 100)
    }
  }

  checkIfExtensionInstalled = () => {
    const extensionDivId = 'aurora-bvf'
    const hasExtension = isBrowser ? document.getElementById(extensionDivId) : true
    this.setState({ hasExtension: hasExtension ? true : false })
  }

  isChromeBrowser = () => {
    // https://stackoverflow.com/questions/4565112/javascript-how-to-find-out-if-the-user-browser-is-chrome/13348618#13348618
    var isChromium = window.chrome
    var winNav = window.navigator
    var vendorName = winNav.vendor
    var isOpera = typeof window.opr !== 'undefined'
    var isIEedge = winNav.userAgent.indexOf('Edge') > -1
    var isIOSChrome = winNav.userAgent.match('CriOS')
    if (isIOSChrome) {
      return true
    } else if (
      isChromium !== null &&
      typeof isChromium !== 'undefined' &&
      vendorName === 'Google Inc.' &&
      isOpera === false &&
      isIEedge === false
    ) {
      return true
    }
    return false
  }

  handleCopyLink = (event) => {
    copyLinkToClipboard()
    this.setState({ clipBoardUpdated: true })
  }

  render() {
    const withRaivenDomain = this.props.featureFlags.f6853QmeritRedirect
    const href = withRaivenDomain ? 'https://marketplace.raiven.com' : 'https://marketplace.qmerit.com'
    return (
      <Fragment>
        <main role="main" className="flex-row">
          {this.state.hasExtension && <Header {...this.props}></Header>}
          <article className={`onboarding-wrapper ${this.state.hasExtension ? 'header-included' : ''}`}>
            {!this.state.hasExtension && (
              <Fragment>
                <div className="onboarding-content-wrapper welcome">
                  <h1>Welcome, {this.state.profile.FirstName.trim()}!</h1>
                  {this.state.profile.Network[0].DBAName && this.state.profile.Network[0].DBAName.length > 0 && (
                    <h2 className="company-section">You've been added to {this.state.profile.Network[0].DBAName}</h2>
                  )}

                  <Fragment>
                    <h3>Next, add the Raiven Marketplace to get started</h3>
                    <p className="purchasing-details">
                      Purchasing for {this.state.profile.Network[0].DBAName} is managed in the Raiven Marketplace
                    </p>
                    <a href={this.state.chromeLink} className="button">
                      Add the Raiven Marketplace
                    </a>
                    {!this.state.isChrome && (
                      <div className="alert">
                        <i className="far fa-exclamation-circle" />
                        <p>
                          The Raiven Marketplace works best in your Google Chrome web browser. See instructions at the
                          bottom of this page if you need to download Google Chrome first.{' '}
                        </p>
                      </div>
                    )}
                    <p className="download-details">
                      {this.state.isChrome
                        ? 'If you are unable to add the extension to your web browser, you can go straight to '
                        : 'If you are unable to download Chrome, or add the extension to your web browser, you can go straight to '}
                      <a href={href} target="_blank" rel="noopener noreferrer">
                        {withRaivenDomain ? 'marketplace.raiven.com.' : 'marketplace.qmerit.com.'}
                      </a>{' '}
                      Please note that without the extension, the Best Value Finder will not be able to dynamically show
                      pricing from all your suppliers while you shop.
                    </p>
                  </Fragment>
                </div>
                <div className="onboarding-content-wrapper section2">
                  <section>
                    <img
                      className="screenshot-sample"
                      src="https://stsharedservices1prd.blob.core.windows.net/procurement/desktop-bva.png"
                      alt="marketplace screen shot"
                    />
                  </section>
                  <section className="image-height-compensation marginTopBottom">
                    <h3>Raiven Marketplace makes purchasing and saving simple</h3>
                    <ul>
                      <li>
                        <i class="fas fa-check"></i>
                        Quickly find and purchase products
                      </li>
                      <li>
                        <i class="fas fa-check"></i>
                        Get significant savings on everyday purchases
                      </li>
                      <li>
                        <i class="fas fa-check"></i>
                        Easily search for products across multiple suppliers
                      </li>
                      <li>
                        <i class="fas fa-check"></i>
                        Order from various suppliers in one shopping cart
                      </li>
                    </ul>
                  </section>
                </div>

                <div className="onboarding-content-wrapper">
                  <section className="image-height-compensation marginTopBottom">
                    <h3>Never miss a better option with the Best Value Finder</h3>
                    <ul>
                      <li>
                        <i class="fas fa-check"></i>
                        Alerts for lower prices on products you're viewing
                      </li>
                      <li>
                        <i class="fas fa-check"></i>
                        Find matched and similar products from your suppliers
                      </li>
                      <li>
                        <i class="fas fa-check"></i>
                        One-click purchasing for lower priced product
                      </li>
                    </ul>
                  </section>
                  <section>
                    <video controls width="544">
                      <source
                        src="https://stsharedservices1prd.blob.core.windows.net/procurement/Qmerit%27s-Best-Value-Finder.mp4"
                        type="video/mp4"
                      />
                      <track
                        default
                        kind="captions"
                        srcLang="en"
                        src="https://stsharedservices1prd.blob.core.windows.net/procurement/Qmerit%27s%20Best%20Value%20Finder.mp4.srt"
                      />
                      <p>Sorry, your browser doesn't support embedded videos.</p>
                    </video>
                  </section>
                </div>

                <div className="onboarding-content-wrapper onboarding-get-bvf">
                  {!this.state.isChrome && (
                    <section className="not-chrome">
                      <h3>Start using the Raiven Marketplace</h3>
                      <p>
                        For the full experience, you need to have a Google Chrome web browser installed on your
                        computer.
                      </p>
                      <div className="onboarding-steps">
                        <div className="step step1">
                          <h3>Step 1</h3>
                          <p>
                            Copy this link and use it in your Chrome browser. If you don’t have Chrome installed yet, go
                            to Step 2.
                          </p>
                          <form id="chrome-link-form" action="()=>undefined">
                            <input
                              id="chrome-link"
                              value={this.state.chromeLink}
                              type="text"
                              onChange={() => undefined}
                            />
                            {!this.state.isIEedge && (
                              <button
                                className="copy-link"
                                type="button"
                                id="chrome-link-copy"
                                onClick={() => this.handleCopyLink()}
                              >
                                Copy Link
                              </button>
                            )}
                          </form>
                          <i>
                            This is a link to the Google Chrome Web Store. This is where you will choose “Add to Chrome”
                            for the Raiven Marketplace.
                          </i>
                        </div>
                        <div className="line"></div>
                        <div className="step step2">
                          <h3>Step 2</h3>
                          <p>If you don’t already have the Chrome browser, install it using the link below.</p>
                          <a
                            className="chrome-download-link"
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://www.google.com/chrome/"
                            title="download google chrome"
                          >
                            <img
                              className="chrome-logo"
                              src="https://upload.wikimedia.org/wikipedia/commons/a/a5/Google_Chrome_icon_%28September_2014%29.svg"
                              alt="download chrome logo"
                            />
                            Download Google Chrome
                          </a>
                        </div>
                      </div>
                      <p className="download-details">
                        {this.state.isChrome
                          ? 'If you are unable to add the extension to your web browser, you can go straight to '
                          : 'If you are unable to download Chrome, or add the extension to your web browser, you can go straight to '}
                        <a href={href} target="_blank" rel="noopener noreferrer">
                          {withRaivenDomain ? 'marketplace.raiven.com.' : 'marketplace.qmerit.com.'}
                        </a>{' '}
                        Please note that without the extension, the Best Value Finder will not be able to dynamically
                        show pricing from all your suppliers while you shop.
                      </p>
                    </section>
                  )}
                  {this.state.isChrome && (
                    <section className="with-chrome">
                      <h3>Start using the Raiven Marketplace</h3>
                      <p>Access the full feature set of the Raiven Marketplace using your Google Chrome web browser.</p>
                      <a href={this.state.chromeLink} class="button footer-button">
                        Add the Raiven Marketplace
                      </a>
                      <p className="narrow-p">
                        This is a link to the Google Chrome Web Store. This is where you will choose “Add to Chrome” for
                        the Raiven Marketplace.
                      </p>
                      <p className="download-details">
                        {this.state.isChrome
                          ? 'If you are unable to add the extension to your web browser, you can go straight to '
                          : 'If you are unable to download Chrome, or add the extension to your web browser, you can go straight to '}
                        <a href={href} target="_blank" rel="noopener noreferrer">
                          {withRaivenDomain ? 'marketplace.raiven.com.' : 'marketplace.qmerit.com.'}
                        </a>{' '}
                        Please note that without the extension, the Best Value Finder will not be able to dynamically
                        show pricing from all your suppliers while you shop.
                      </p>
                    </section>
                  )}
                </div>
              </Fragment>
            )}
            {/* {this.state.hasExtension &&
                            <Fragment>
                                <h1>The Best Value Finder is now ready to use!</h1>
                                <div className="onboarding-section-wrapper">
                                    <section>
                                        <img className="screenshot-sample" src="https://stsharedservices1prd.blob.core.windows.net/procurement/desktop-cart.png" alt="marketplace screen shot"/>
                                    </section>
                                    <section className="image-height-more-compensation">
                                        <h3>Welcome to the MRO Marketplace</h3>
                                        <ul>
                                            <li><i class="fas fa-check"></i>Save on MRO purchases from contracted suppliers</li>
                                            <li><i class="fas fa-check"></i>Fast shipping (contracted suppliers)</li>
                                            <li><i class="fas fa-check"></i>Search for products across multiple suppliers</li>
                                            <li><i class="fas fa-check"></i>Paperless order tracking, order approval, and compliance</li>
                                        </ul>
                                        <button className="button-link" onClick={event=>this.handleLetsGo(event)}>Let's Go</button>
                                    </section>
                                </div>
                            </Fragment>
                        } */}
            {this.state.clipBoardUpdated && <ToastMessage type="success" message="Copied to clipboard" />}
            {this.state.hasExtension && (
              <Fragment>
                <h1 className="onboarding-success-header">The Raiven Marketplace is now ready to use!</h1>
                <div className="get-started-message">
                  <svg width="21px" height="22px" viewBox="0 0 21 22" version="1.1">
                    <g id="The-Dumpster" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                      <g
                        id="d-Onboarding---You're-All-Set"
                        transform="translate(-145.000000, -219.000000)"
                        fill="#00468B"
                        fillRule="nonzero"
                      >
                        <path
                          d="M156.156223,240.499996 C156.466861,240.499996 156.718722,240.248135 156.718722,239.937498 L156.718722,223.932928 L163.990418,231.204624 C164.210098,231.424258 164.566238,231.424258 164.785918,231.204624 L165.714031,230.276556 C165.933666,230.056876 165.933666,229.700736 165.714031,229.48101 L155.897725,219.664795 C155.678045,219.445161 155.321905,219.445161 155.102179,219.664795 L145.285964,229.481056 C145.066284,229.700736 145.066284,230.056876 145.285964,230.276556 L146.214078,231.204624 C146.433712,231.424258 146.789852,231.424258 147.009578,231.204624 L154.281228,223.932928 L154.281228,239.937498 C154.281228,240.248135 154.533089,240.499996 154.843727,240.499996 L156.156223,240.499996 Z"
                          id="a"
                        ></path>
                      </g>
                    </g>
                  </svg>
                  <p>Click the Marketplace tab to start exploring&hellip;</p>
                </div>
              </Fragment>
            )}
          </article>
        </main>
      </Fragment>
    )
  }
}

export default OnBoarding
