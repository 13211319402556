import React, { useState, Fragment, useEffect, useCallback } from 'react'
import _ from 'lodash'
import Header from '../../components/Header'
import Loader from '../../components/Loader'
import Store from '../../../../lib/@q-core/store-component/distribute'
// import StoreHome from "./StoreHome";
import defaultStoresConfig from './defaultStoresConfig'
import { safeLocalStorage } from '../../../utils/safeStorage'
import webFetch from '../../../utils/webFetch'

const handleATC = ({ cart, setCartItemTotal }) => {
  safeLocalStorage.setJSONItem('cart', { ...cart })
  setCartItemTotal(cart.CartItemTotal)
}

const getActiveCompany = profile => {
  let activeCompany
  if (!_.isNil(profile) && _.has(profile, 'Network') && _.isArray(profile.Network)) {
    _.map(profile.Network, (company, index) => {
      if (company.Id === profile.activeNetworkId) {
        activeCompany = company
      }
    })
  }

  if (_.isNil(activeCompany)) {
    activeCompany = profile.Network[0]
  }

  return activeCompany
}

const Stores = props => {
  const isBrowser = typeof window !== 'undefined' && window.env
  const apiRoot = isBrowser ? window.env.apiRoot : process.env.RAZZLE_RUNTIME_API_ROOT
  const apiKey = isBrowser ? window.env.apiKey : process.env.RAZZLE_RUNTIME_API_KEY
  const blobUrl = isBrowser ? window.env.blobUrl : process.env.RAZZLE_RUNTIME_BLOB_URL
  const harmonyRootUrl = isBrowser ? window.env.harmonyRootUrl : process.env.RAZZLE_RUNTIME_HARMONY_ROOT_URL
  const profile = safeLocalStorage.getJSONItem('userData')
  const corpInfo = getActiveCompany(profile)
  const userToken = corpInfo.UserToken

  const [cartItemTotal, setCartItemTotal] = useState(null)
  const [cartUpdateDone, setCartUpdateDone] = useState(false)
  const [storesConfig, setStoresConfig] = useState()
  const [selectedStoreName] = useState(props.match.params.storeName)
  const [selectedStoreConfig, setSelectedStoreConfig] = useState()
  const [ENV] = useState({
    isBrowser,
    apiRoot,
    apiKey,
    blobUrl,
    harmonyRootUrl,
  })

  useEffect(() => {
    if (storesConfig) {
      const newSelectedStore = storesConfig.find(config => config.url === selectedStoreName)
      setSelectedStoreConfig(newSelectedStore)
    }
  }, [storesConfig, selectedStoreName])

  useEffect(() => {
    const loadStoreConfig = async () => {
      if (ENV.harmonyRootUrl) {
        const harmonyStoresUrl = `${ENV.RootUrl}/api/v2/stores/categories`        
        const token = safeLocalStorage.getItem('token');
        const cacheTimeInSeconds = 60 * 60 * 12 // seconds in a minute X minutes in an hour X hours to cache
        let harmonyPpeConfig = await webFetch(
          harmonyStoresUrl,
          {
            method: 'GET',
            headers: { 'Cache-Control': `max-age=${cacheTimeInSeconds}`,'Authorization': `Bearer ${token}` },
          },
          'harmonyPpeConfig'
        )
        if (harmonyPpeConfig && harmonyPpeConfig.length > 1)
          harmonyPpeConfig = harmonyPpeConfig.filter(store => !store.Error)
        if (harmonyPpeConfig && harmonyPpeConfig[0] && harmonyPpeConfig[0].title) {
          console.log('Harmony responded with good data :: Loading harmony Stores config')
          setStoresConfig(harmonyPpeConfig)
        } else {
          console.log('Bad Harmony Data :: Loading default Stores config')
          setStoresConfig(defaultStoresConfig)
        }
      } else {
        console.log('No Harmony Data :: Loading default Stores config')
        setStoresConfig(defaultStoresConfig)
      }
    }
    loadStoreConfig()
    // setStoresConfig(defaultStoresConfig);
  }, [])

  const handleSetCartItemTotal = useCallback(val => {
    setCartItemTotal(val)
    setCartUpdateDone(true)
  }, [])

  const handleCartUpdate = useCallback(cartVal => {
    setCartItemTotal(cartVal.CartItemTotal)
  }, [])

  const handleResetCartUpdate = useCallback(() => {
    setCartUpdateDone(false)
  }, [])

  return (
    <Fragment>
      <Header
        {...props}
        cartItemTotal={cartItemTotal}
        cartUpdateDone={cartUpdateDone}
        onCartUpdate={handleCartUpdate}
        onResetCartUpdate={handleResetCartUpdate}
      />
      <main role="main" className="flex-row stores-feature">
        <article>
          {selectedStoreConfig ? (
            <Store
              config={selectedStoreConfig}
              router={props}
              userToken={userToken}
              bearerToken={safeLocalStorage.getItem('token')}
              apiKey={apiKey}
              apiUrl={apiRoot}
              blobUrl={blobUrl}
              onATC={cart => handleATC({ cart, setCartItemTotal: handleSetCartItemTotal })}
            />
          ) : (
            <Loader />
          )}
          {
            // currently defaulting to first store, if none selected in url, if we don't
            // want to select the first store automatically, use this comp to render a store
            // home page for selecting a store
            // <StoreHome config={storesConfig} router={props} />
          }
        </article>
      </main>
    </Fragment>
  )
}

export default Stores
