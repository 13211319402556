import React, { useState } from 'react'
import ComponentStyles from './styles'
import { safeLocalStorage } from '../../../../../../utils/safeStorage';
import { useFlags } from 'launchdarkly-react-client-sdk'

const userData = safeLocalStorage.getJSONItem('userData');
const { UnifiBanner } = ComponentStyles

export const TempUnifiNotification = () => {
    const [hasClicked, setHasClicked] = useState(userData.hasClickedUnifiNotification || false);

    const featureFlags = useFlags()
    const userEmail = userData?.Email ?? '';

    const showTempUnifiNotification = featureFlags.f22465UnifiBanner 
        && !hasClicked
        &&  (
                   userEmail.endsWith("@unifiservice.com")
                || userEmail.endsWith("@globalservices.com")
                || (userEmail.startsWith("raiven.claw.ext") && userEmail.endsWith("@gmail.com"))
            );

    const handleButtonClick = () => {
        userData.hasClickedUnifiNotification = true;
        safeLocalStorage.setJSONItem('userData', userData);
        setHasClicked(true);
    };
    
    return (
        showTempUnifiNotification &&
      <UnifiBanner >
        <div className='main-container'>
            <div className='text-container'>
            <i className="far fa-exclamation-triangle warning-icon" />
                Attention: Unifi is no longer purchasing janitorial and office supplies from Office Depot. <strong >All purchases must be made from Staples.</strong>
                <button className='got-it-button' onClick={handleButtonClick}>Got it</button>
            </div>
        </div>
      </UnifiBanner>
    )
}

export default TempUnifiNotification